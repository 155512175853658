import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './navbar/navbar.component'
import Footer from './footer/footer.component'
import { useStaticQuery, graphql } from 'gatsby'
import MobileNav from './navbar/mobile-nav.component'

import '../styles/index.styles.scss'

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className="main">{children}</main>
      <Footer />
      <MobileNav />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
