import React from 'react'
import { Link } from 'gatsby'
import { FaHome, FaBlog, FaInfo, FaSuitcase } from 'react-icons/fa'

const MobileNav = () => {
  return (
    <nav className="MobileNav">
      <ul className="MobileNav__links">
        <Link
          to="/"
          className="MobileNav__link"
          activeClassName="MobileNav__link--active"
        >
          <FaHome />
        </Link>
        <Link
          to="/portfolio"
          className="MobileNav__link"
          activeClassName="MobileNav__link--active"
        >
          <FaSuitcase />
        </Link>
        <Link
          to="/blog"
          className="MobileNav__link"
          activeClassName="MobileNav__link--active"
        >
          <FaBlog />
        </Link>
      </ul>
    </nav>
  )
}

export default MobileNav
