import React from 'react'
import PropTypes from 'prop-types'

const CallToAction = ({ showClass, toggleModal }) => (
  <button
    onClick={toggleModal}
    className={`Btn Btn--call-to-action ${showClass}`}
  >
    Get A Quote
  </button>
)

CallToAction.propTypes = {
  showClass: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default CallToAction
