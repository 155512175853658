import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

const Modal = ({ toggleModal }) => (
  <Fragment>
    <div className={`Modal`}>
      <h3 className="Modal__title">Get a quote</h3>
      <div className="Modal__text">
        <p>Contact us for any inquires</p>
        <a href="mailto: anson.ervin@ansonervin.com">
          <p>
            <FaEnvelope className="Modal__icon" /> anson.ervin@ansonervin.com
          </p>
        </a>

        <a href="tel:9737232241">
          <p>
            <FaPhone className="Modal__icon" /> (973) 723 2241
          </p>
        </a>
      </div>

      <button onClick={toggleModal} className="Btn Btn--dark">
        Close
      </button>
    </div>
    <div className="overlay"></div>
  </Fragment>
)

Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
}

export default Modal
